import { createIcon } from '../createIcon';

export const EmailNotificationIcon = createIcon({
  svg: () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.06746 0C-0.0159125 0 -0.408126 1.48677 0.523415 2.06231L9.84615 7.82222L19.1689 2.06231C20.1004 1.48677 19.7082 0 18.6248 0H1.06746ZM0.886111 1.42667C0.575597 1.23483 0.706335 0.739236 1.06746 0.739236H18.6248C18.986 0.739236 19.1167 1.23483 18.8062 1.42667L9.84615 6.9625L0.886111 1.42667Z"
        fill="currentColor"
      />
      <path
        d="M0.703297 2.84444C0.703297 2.64808 0.545858 2.48889 0.351648 2.48889C0.157438 2.48889 0 2.64808 0 2.84444V14.2222C0 15.2041 0.787192 16 1.75824 16H17.9341C18.9051 16 19.6923 15.2041 19.6923 14.2222V2.84444C19.6923 2.64808 19.5349 2.48889 19.3407 2.48889C19.1465 2.48889 18.989 2.64808 18.989 2.84444V14.2222C18.989 14.8113 18.5167 15.2889 17.9341 15.2889H1.75824C1.17561 15.2889 0.703297 14.8113 0.703297 14.2222V2.84444Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 20 16',
});
