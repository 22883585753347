export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type CalculatedRiskProfile = {
  __typename?: 'CalculatedRiskProfile';
  messageAlert?: Maybe<Type_CalculatedRiskProfileQuestion>;
  calculatedRisk?: Maybe<Enum_PortfolioSelection>;
};

export enum CognitoGroup {
  MasterAdmin = 'masterAdmin',
  IfaCompanyAdmin = 'ifaCompanyAdmin',
  Ifa = 'ifa',
}

export type CompanyType = {
  __typename?: 'companyType';
  name?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyUserInput = {
  email: Scalars['String']['input'];
  brandColor?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};

export type CumulativePerformanceInput = {
  topTableLabel?: InputMaybe<Scalars['String']['input']>;
  top1m?: InputMaybe<Scalars['String']['input']>;
  top3m?: InputMaybe<Scalars['String']['input']>;
  top6m?: InputMaybe<Scalars['String']['input']>;
  top1y?: InputMaybe<Scalars['String']['input']>;
  top3y?: InputMaybe<Scalars['String']['input']>;
  top5y?: InputMaybe<Scalars['String']['input']>;
  topInception?: InputMaybe<Scalars['String']['input']>;
  bottomTableLabel?: InputMaybe<Scalars['String']['input']>;
  bottom1m?: InputMaybe<Scalars['String']['input']>;
  bottom3m?: InputMaybe<Scalars['String']['input']>;
  bottom6m?: InputMaybe<Scalars['String']['input']>;
  bottom1y?: InputMaybe<Scalars['String']['input']>;
  bottom3y?: InputMaybe<Scalars['String']['input']>;
  bottom5y?: InputMaybe<Scalars['String']['input']>;
  bottomInception?: InputMaybe<Scalars['String']['input']>;
  assumedFees?: InputMaybe<Scalars['String']['input']>;
};

export type CumulativePerformanceType = {
  __typename?: 'cumulativePerformanceType';
  topTableLabel?: Maybe<Scalars['String']['output']>;
  top1m?: Maybe<Scalars['String']['output']>;
  top3m?: Maybe<Scalars['String']['output']>;
  top6m?: Maybe<Scalars['String']['output']>;
  top1y?: Maybe<Scalars['String']['output']>;
  top3y?: Maybe<Scalars['String']['output']>;
  top5y?: Maybe<Scalars['String']['output']>;
  topInception?: Maybe<Scalars['String']['output']>;
  bottomTableLabel?: Maybe<Scalars['String']['output']>;
  bottom1m?: Maybe<Scalars['String']['output']>;
  bottom3m?: Maybe<Scalars['String']['output']>;
  bottom6m?: Maybe<Scalars['String']['output']>;
  bottom1y?: Maybe<Scalars['String']['output']>;
  bottom3y?: Maybe<Scalars['String']['output']>;
  bottom5y?: Maybe<Scalars['String']['output']>;
  bottomInception?: Maybe<Scalars['String']['output']>;
  assumedFees?: Maybe<Scalars['String']['output']>;
};

export enum Enum_ConfidenceComposure {
  ImmediatelySwitch = 'immediatelySwitch',
  WaitSixMonth = 'waitSixMonth',
  Stay = 'stay',
}

export enum Enum_Income_WithdrawalType {
  Capital = 'capital',
  Income = 'income',
}

export enum Enum_JourneyVersion {
  V2024_04_23 = 'v2024_04_23',
  V2024_05_17 = 'v2024_05_17',
}

export enum Enum_KnowledgeExperience_Duration {
  Short = 'short',
  Med = 'med',
  Long = 'long',
}

export enum Enum_KnowledgeExperience_TypeAsset {
  SingleStocksBonds = 'singleStocksBonds',
  Commodities = 'commodities',
  CollectiveFunds = 'collectiveFunds',
  StructuredProducts = 'structuredProducts',
  AbsoluteReturnHedgeFund = 'absoluteReturnHedgeFund',
  PrivateEquity = 'privateEquity',
}

export enum Enum_KnowledgeExperience_TypeService {
  DiscretionaryManagment = 'discretionaryManagment',
  Advisory = 'advisory',
  ExecutionOnlySelfTrade = 'executionOnlySelfTrade',
}

export enum Enum_KnwoledgeRange {
  NotImportant = 'notImportant',
  Considered = 'considered',
  Focus = 'focus',
}

export enum Enum_PastExperience {
  None = 'none',
  NotEmotionally = 'notEmotionally',
  UncomfortableWithoutFeelRisk = 'uncomfortableWithoutFeelRisk',
  UncomfortableWithLoseEverything = 'uncomfortableWithLoseEverything',
}

export enum Enum_PortfolioCost {
  CostConsidered = 'costConsidered',
  CostFocus = 'costFocus',
}

export enum Enum_PortfolioSelection {
  Low = 'low',
  Lower = 'lower',
  Medium = 'medium',
  Higher = 'higher',
  High = 'high',
}

export enum Enum_PortfolioStyle {
  Core = 'core',
  Income = 'income',
  ImpactAndInnovation = 'impactAndInnovation',
  Passive = 'passive',
  Satellite = 'satellite',
  MultiStrategy = 'multiStrategy',
  FactsheetSatellite = 'factsheetSatellite',
}

export enum Enum_PortfolioType {
  IndividualAccount = 'individualAccount',
  JointAccount = 'jointAccount',
  Trust = 'trust',
  Company = 'company',
  Isa = 'isa',
  Other = 'other',
}

export enum Enum_ReasonsInvesting_RetirementPlanning {
  AccumulationPhase = 'accumulationPhase',
  LifestylePhase = 'lifestylePhase',
  DrawdownPhase = 'drawdownPhase',
}

export enum Enum_ReasonsInvesting_SaveAndInvest {
  CapitalGrowth = 'capitalGrowth',
  IncomeAndGrowth = 'incomeAndGrowth',
  Incomeprovision = 'incomeprovision',
}

export enum Enum_ReasonsInvesting_SelectedName {
  SaveAndInvest = 'saveAndInvest',
  RetirementPlanning = 'retirementPlanning',
  TargetInvesting = 'targetInvesting',
  SupplementaryIncome = 'supplementaryIncome',
}

export enum Enum_ReasonsInvesting_SupplementaryIncome {
  AdHocIncome = 'adHocIncome',
  IncomeAndGrowth = 'incomeAndGrowth',
  RegularIncome = 'regularIncome',
}

export enum Enum_ReasonsInvesting_TargetInvesting {
  AccumulationPhase = 'accumulationPhase',
  Preservation = 'preservation',
  IncomeWithdrawal = 'incomeWithdrawal',
}

export enum Enum_ReportPdfPages {
  IntroPage = 'introPage',
  Factsheets = 'factsheets',
}

export enum Enum_ResponsibleInvesting_Question1 {
  NotImportant = 'notImportant',
  SomeConsideration = 'someConsideration',
  FactorsCoreElement = 'factorsCoreElement',
}

export enum Enum_ResponsibleInvesting_Question2 {
  NoSpecificRequirements = 'noSpecificRequirements',
  InvestmentFlexibility = 'investmentFlexibility',
  ResponsibleInvesting = 'responsibleInvesting',
}

export enum Enum_RiskCapacity_Question1 {
  None = 'none',
  Small = 'small',
  Large = 'large',
}

export enum Enum_RiskCapacity_Question2 {
  AlmostCertainly = 'almostCertainly',
  Maybe = 'maybe',
  OtherSavings = 'otherSavings',
}

export enum Enum_RiskName {
  Low = 'low',
  Lower = 'lower',
  Medium = 'medium',
  Higher = 'higher',
  High = 'high',
}

export enum Enum_RiskProfile_SelectedName {
  MapThirdPart = 'mapThirdPart',
  CompleteRisk = 'completeRisk',
}

export type Estimation = {
  __typename?: 'Estimation';
  history?: Maybe<Array<Maybe<History>>>;
  increase?: Maybe<Increase>;
  minSearchDate?: Maybe<Scalars['String']['output']>;
  maxSearchDate?: Maybe<Scalars['String']['output']>;
};

export type FactSheet = {
  __typename?: 'FactSheet';
  factSheetId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subName?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyType>;
  ownedByDisplayName?: Maybe<Scalars['String']['output']>;
  ownedBy?: Maybe<Scalars['String']['output']>;
  objective?: Maybe<Scalars['String']['output']>;
  marketPortfolioUpdate?: Maybe<Scalars['String']['output']>;
  portfolioPositioningChanges?: Maybe<Scalars['String']['output']>;
  cumulativePerformance?: Maybe<CumulativePerformanceType>;
  portfolioCharacteristics?: Maybe<PortfolioCharacteristicsType>;
  timeStamp?: Maybe<TimeStampType>;
  fixedIncome?: Maybe<Array<Maybe<NameValueType>>>;
  realReturn?: Maybe<Array<Maybe<NameValueType>>>;
  absoluteReturn?: Maybe<Array<Maybe<NameValueType>>>;
  equities?: Maybe<Array<Maybe<NameValueType>>>;
  realAssets?: Maybe<Array<Maybe<NameValueType>>>;
  portfolioStyle?: Maybe<Enum_PortfolioStyle>;
  risk?: Maybe<Enum_PortfolioSelection>;
  riskWarnings?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
};

export type History = {
  __typename?: 'History';
  date?: Maybe<Scalars['String']['output']>;
  low?: Maybe<Scalars['Float']['output']>;
  lower?: Maybe<Scalars['Float']['output']>;
  medium?: Maybe<Scalars['Float']['output']>;
  higher?: Maybe<Scalars['Float']['output']>;
  high?: Maybe<Scalars['Float']['output']>;
  additionalData?: Maybe<Array<Maybe<AdditionalData>>>;
};

export type IfaUserInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};

export type Increase = {
  __typename?: 'Increase';
  amounts?: Maybe<Values>;
  procents?: Maybe<Values>;
};

export type Input_ChosenSatellites = {
  name: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
  risk: Scalars['Int']['input'];
  sateliteId: Scalars['String']['input'];
};

export type Input_CompleteRiskProfile = {
  confidenceComposure?: InputMaybe<Enum_ConfidenceComposure>;
  toleranceCapitalLoss?: InputMaybe<Scalars['Int']['input']>;
  feelAboutRisk?: InputMaybe<Input_TwoQuestionAnswerFromOneToFive>;
  riskTolerance?: InputMaybe<Scalars['Int']['input']>;
  pastExperience?: InputMaybe<Enum_PastExperience>;
  riskCapacity?: InputMaybe<Input_RiskCapacity>;
  output?: InputMaybe<Scalars['Int']['input']>;
};

export type Input_Income = {
  notes?: InputMaybe<Scalars['String']['input']>;
  incomeAnnum?: InputMaybe<Scalars['Float']['input']>;
  yield?: InputMaybe<Scalars['Float']['input']>;
  selected?: InputMaybe<Enum_Income_WithdrawalType>;
};

export type Input_IncomeCostSustainability = {
  notes?: InputMaybe<Scalars['String']['input']>;
  income?: InputMaybe<Scalars['Boolean']['input']>;
  cost?: InputMaybe<Enum_KnwoledgeRange>;
  susainability?: InputMaybe<Enum_KnwoledgeRange>;
};

export type Input_KnowledgeExperience = {
  notes?: InputMaybe<Scalars['String']['input']>;
  typeService?: InputMaybe<
    Array<InputMaybe<Enum_KnowledgeExperience_TypeService>>
  >;
  typeAsset?: InputMaybe<Array<InputMaybe<Enum_KnowledgeExperience_TypeAsset>>>;
  duration?: InputMaybe<Enum_KnowledgeExperience_Duration>;
  noInvestment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Input_PortfolioCost = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Enum_PortfolioCost>;
};

export type Input_PortfolioPdf = {
  report?: InputMaybe<Scalars['String']['input']>;
  factsheet?: InputMaybe<Scalars['String']['input']>;
};

export type Input_PortfolioSelection = {
  notes?: InputMaybe<Scalars['String']['input']>;
  riskProfileSelection?: InputMaybe<Enum_PortfolioSelection>;
  portfolioStyle?: InputMaybe<Input_PortfolioStyle>;
};

export type Input_PortfolioStyle = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Enum_PortfolioStyle>;
};

export type Input_PortfolioType = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Array<InputMaybe<PortfolioTypeEntityInput>>>;
};

export type Input_ReasonsInvesting = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selectedName?: InputMaybe<Enum_ReasonsInvesting_SelectedName>;
  saveAndInvest?: InputMaybe<Enum_ReasonsInvesting_SaveAndInvest>;
  retirementPlanning?: InputMaybe<Enum_ReasonsInvesting_RetirementPlanning>;
  targetInvesting?: InputMaybe<Enum_ReasonsInvesting_TargetInvesting>;
  supplementaryIncome?: InputMaybe<Enum_ReasonsInvesting_SupplementaryIncome>;
};

export type Input_ReportPdfOptions = {
  excludedPages?: InputMaybe<Array<InputMaybe<Enum_ReportPdfPages>>>;
};

export type Input_ResponsibleInvesting = {
  notes?: InputMaybe<Scalars['String']['input']>;
  question1?: InputMaybe<Enum_ResponsibleInvesting_Question1>;
  question2?: InputMaybe<Enum_ResponsibleInvesting_Question2>;
};

export type Input_RiskCapacity = {
  question1?: InputMaybe<Enum_RiskCapacity_Question1>;
  question2?: InputMaybe<Enum_RiskCapacity_Question2>;
};

export type Input_RiskProfile = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selectedName?: InputMaybe<Enum_RiskProfile_SelectedName>;
  completeRiskProfile?: InputMaybe<Input_CompleteRiskProfile>;
};

export type Input_SatellitePortfolios = {
  notes?: InputMaybe<Scalars['String']['input']>;
  chosenSatellites?: InputMaybe<Array<InputMaybe<Input_ChosenSatellites>>>;
};

export type Input_Source = {
  id: Scalars['String']['input'];
  name: Enum_PortfolioType;
  value: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  portfolios?: InputMaybe<Array<Input_SourcePortfolio>>;
};

export type Input_SourcePortfolio = {
  key: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
  risk: Enum_PortfolioSelection;
  cost?: InputMaybe<Scalars['Float']['input']>;
};

export type Input_TaxWrapper = {
  notes?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<Array<Input_Source>>;
};

export type Input_TimeHorizon = {
  notes?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Scalars['Float']['input']>;
};

export type Input_TwoQuestionAnswerFromOneToFive = {
  question1?: InputMaybe<Scalars['Int']['input']>;
  question2?: InputMaybe<Scalars['Int']['input']>;
};

export type Journey = {
  __typename?: 'Journey';
  journeyId: Scalars['ID']['output'];
  version?: Maybe<Enum_JourneyVersion>;
  notes?: Maybe<Scalars['String']['output']>;
  step?: Maybe<JourneyStep>;
  completedSteps?: Maybe<Array<Maybe<JourneyStep>>>;
  status?: Maybe<Scalars['String']['output']>;
  complete?: Maybe<Scalars['Boolean']['output']>;
  isShort?: Maybe<Scalars['Boolean']['output']>;
  isRiskProfile?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  investorName?: Maybe<Scalars['String']['output']>;
  portfolioName?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  portfolioValue?: Maybe<Scalars['Float']['output']>;
  investorEmail?: Maybe<Scalars['String']['output']>;
  portfolioType?: Maybe<Type_PortfolioType>;
  reasonsInvesting?: Maybe<Type_ReasonsInvesting>;
  income?: Maybe<Type_Income>;
  timeHorizon?: Maybe<Type_TimeHorizon>;
  knowledgeExperience?: Maybe<Type_KnowledgeExperience>;
  responsibleInvesting?: Maybe<Type_ResponsibleInvesting>;
  riskProfile?: Maybe<Type_RiskProfile>;
  incomeCostSustainability?: Maybe<Type_IncomeCostSustainability>;
  approvedRisk?: Maybe<Scalars['Boolean']['output']>;
  portfolioSelection?: Maybe<Type_PortfolioSelection>;
  satelliteSelection?: Maybe<Scalars['ID']['output']>;
  satellitePortfolios?: Maybe<Type_SatellitePortfolios>;
  taxWrapper?: Maybe<Type_TaxWrapper>;
  portfolioCost?: Maybe<Type_PortfolioCost>;
  pdf?: Maybe<Type_PortfolioPdf>;
  reportOptions?: Maybe<Type_ReportPdfOptions>;
};

export type JourneyInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  step: JourneyStep;
  isShort?: InputMaybe<Scalars['Boolean']['input']>;
  isRiskProfile?: InputMaybe<Scalars['Boolean']['input']>;
  completedSteps?: InputMaybe<Array<InputMaybe<JourneyStep>>>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  portfolioName?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  portfolioValue?: InputMaybe<Scalars['Float']['input']>;
  investorEmail?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
};

export enum JourneyStep {
  BasicInformation = 'basicInformation',
  PortfolioType = 'portfolioType',
  ReasonsInvesting = 'reasonsInvesting',
  ReasonsInvestingSelected = 'reasonsInvesting_selected',
  Income = 'income',
  TimeHorizon = 'timeHorizon',
  KnowledgeExperience = 'knowledgeExperience',
  ResponsibleInvesting = 'responsibleInvesting',
  RiskProfile = 'riskProfile',
  MapThirdPart = 'mapThirdPart',
  RiskTolerance = 'riskTolerance',
  ToleranceCapitalLoss = 'toleranceCapitalLoss',
  FeelAboutRisk = 'feelAboutRisk',
  ConfidenceComposure = 'confidenceComposure',
  PastExperience = 'pastExperience',
  CapacityLoss = 'capacityLoss',
  RiskProfileSelection = 'riskProfileSelection',
  PortfolioStyle = 'portfolioStyle',
  SatelliteSelection = 'satelliteSelection',
  SuggestedPortfolio = 'suggestedPortfolio',
  SatellitePortfolios = 'satellitePortfolios',
  TaxWrapper = 'taxWrapper',
  ReportFactsheet = 'reportFactsheet',
  PortfolioCost = 'portfolioCost',
}

export type Label = {
  __typename?: 'Label';
  labelId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  boom?: Maybe<Scalars['Boolean']['output']>;
};

export type LabelInput = {
  name: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  boom: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateFactSheet?: Maybe<FactSheet>;
  createJourney?: Maybe<Journey>;
  updateJourney?: Maybe<Journey>;
  refreshReport?: Maybe<Journey>;
  deleteJourney?: Maybe<Scalars['Boolean']['output']>;
  createLabel?: Maybe<Label>;
  updateLabel?: Maybe<Label>;
  deleteLabel?: Maybe<Scalars['Boolean']['output']>;
  updatePdfChart?: Maybe<PdfChart>;
  createPortfolio?: Maybe<Portfolio>;
  updatePortfolio?: Maybe<Portfolio>;
  uploadReturns?: Maybe<Returns>;
  updateRisk?: Maybe<Risk>;
  updateStatistics?: Maybe<Statistics>;
  createIFAAdminCompany?: Maybe<User>;
  createIFA?: Maybe<User>;
  removeIFA?: Maybe<Scalars['Boolean']['output']>;
  setIFAStatus?: Maybe<User>;
  setIFACompanyStatus?: Maybe<User>;
  updateIFAAdminCompany?: Maybe<User>;
  updateIFA?: Maybe<User>;
  resendFirstPassword?: Maybe<Result>;
  resetUserPassword?: Maybe<ResetPasswordResult>;
  markAllMarketUpdatesAsRead?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationUpdateFactSheetArgs = {
  factSheet: UpdateFactSheetInput;
};

export type MutationCreateJourneyArgs = {
  journey: JourneyInput;
};

export type MutationUpdateJourneyArgs = {
  journey: UpdateJourneyInput;
};

export type MutationRefreshReportArgs = {
  journeyId: Scalars['ID']['input'];
};

export type MutationDeleteJourneyArgs = {
  journeyId: Scalars['ID']['input'];
};

export type MutationCreateLabelArgs = {
  label: LabelInput;
};

export type MutationUpdateLabelArgs = {
  label: UpdateLabelInput;
};

export type MutationDeleteLabelArgs = {
  labelId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePdfChartArgs = {
  pdfChart: UpdatePdfChartInput;
};

export type MutationCreatePortfolioArgs = {
  portfolio: PortfolioInput;
};

export type MutationUpdatePortfolioArgs = {
  portfolio: UpdatePortfolioInput;
};

export type MutationUploadReturnsArgs = {
  returns: UploadReturnsInput;
};

export type MutationUpdateRiskArgs = {
  risk: UpdateRiskInput;
};

export type MutationUpdateStatisticsArgs = {
  statistics: UpdateStatisticsInput;
};

export type MutationCreateIfaAdminCompanyArgs = {
  input: CompanyUserInput;
};

export type MutationCreateIfaArgs = {
  input: IfaUserInput;
};

export type MutationRemoveIfaArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSetIfaStatusArgs = {
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type MutationSetIfaCompanyStatusArgs = {
  id: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type MutationUpdateIfaAdminCompanyArgs = {
  input: UpdateCompanyUserInput;
};

export type MutationUpdateIfaArgs = {
  input: UpdateIfaUserInput;
};

export type MutationResendFirstPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetUserPasswordArgs = {
  id: Scalars['ID']['input'];
};

export type NameValueInput = {
  name: Scalars['String']['input'];
  value: Scalars['Float']['input'];
  order: Scalars['Int']['input'];
  change?: InputMaybe<Scalars['String']['input']>;
};

export type NameValueType = {
  __typename?: 'nameValueType';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  order: Scalars['Int']['output'];
  change?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  availablePages?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Journey>>>;
};

export type PdfChart = {
  __typename?: 'PdfChart';
  factsheetId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  portfolioChange?: Maybe<Scalars['Float']['output']>;
  benchmarkChange?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type PerformanceSimulationInput = {
  annualisedReturn: Scalars['Float']['input'];
  highestOneYear: Scalars['Float']['input'];
  lowestOneYear: Scalars['Float']['input'];
};

export type Portfolio = {
  __typename?: 'Portfolio';
  portfolioId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  low: Scalars['Float']['output'];
  lower: Scalars['Float']['output'];
  medium: Scalars['Float']['output'];
  higher: Scalars['Float']['output'];
  high: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
};

export type PortfolioCharacteristicsInput = {
  growAssets: Scalars['Float']['input'];
  numberOfHoldings: Scalars['String']['input'];
  ongoingCostOfFunds: Scalars['Float']['input'];
  historicYield?: InputMaybe<Scalars['Float']['input']>;
  totalPortfolioCost?: InputMaybe<Scalars['String']['input']>;
  portfolioManagementFee?: InputMaybe<Scalars['String']['input']>;
  minimumInvestment?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  availableShareClass?: InputMaybe<Scalars['String']['input']>;
};

export type PortfolioCharacteristicsType = {
  __typename?: 'portfolioCharacteristicsType';
  growAssets?: Maybe<Scalars['Float']['output']>;
  numberOfHoldings?: Maybe<Scalars['String']['output']>;
  ongoingCostOfFunds?: Maybe<Scalars['Float']['output']>;
  historicYield?: Maybe<Scalars['Float']['output']>;
  totalPortfolioCost?: Maybe<Scalars['String']['output']>;
  portfolioManagementFee?: Maybe<Scalars['String']['output']>;
  minimumInvestment?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  availableShareClass?: Maybe<Scalars['String']['output']>;
};

export type PortfolioInput = {
  date: Scalars['String']['input'];
  low: Scalars['Float']['input'];
  lower: Scalars['Float']['input'];
  medium: Scalars['Float']['input'];
  higher: Scalars['Float']['input'];
  high: Scalars['Float']['input'];
};

export type PortfolioTypeEntityInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_PortfolioType>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type PortfolioTypeEntityType = {
  __typename?: 'PortfolioTypeEntityType';
  note?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Enum_PortfolioType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getFactSheets?: Maybe<Array<Maybe<FactSheet>>>;
  getFactSheetByArguments?: Maybe<FactSheet>;
  renderFactSheet?: Maybe<FactSheet>;
  previewFactsheet?: Maybe<Scalars['String']['output']>;
  getJourneyById?: Maybe<Journey>;
  getJourneys?: Maybe<Pagination>;
  calculateRiskJourney?: Maybe<CalculatedRiskProfile>;
  getStyleRecommendation?: Maybe<Array<Maybe<StylePreference>>>;
  getLabels?: Maybe<Array<Maybe<Label>>>;
  getPortfolios?: Maybe<Array<Maybe<Portfolio>>>;
  getReturns?: Maybe<Returns>;
  getReturnsFile?: Maybe<Scalars['String']['output']>;
  getRisks?: Maybe<Array<Maybe<Risk>>>;
  getHistory?: Maybe<Estimation>;
  getStatistics?: Maybe<Statistics>;
  getUsersByGroupName?: Maybe<Array<Maybe<User>>>;
  getUserById?: Maybe<User>;
  getUserLogoStatus?: Maybe<User>;
};

export type QueryGetFactSheetByArgumentsArgs = {
  portfolioStyle?: InputMaybe<Enum_PortfolioStyle>;
  risk?: InputMaybe<Enum_PortfolioSelection>;
  ownedBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRenderFactSheetArgs = {
  factsheetId: Scalars['String']['input'];
};

export type QueryPreviewFactsheetArgs = {
  brandColor: Scalars['String']['input'];
  companyAddress: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  logo: Scalars['String']['input'];
};

export type QueryGetJourneyByIdArgs = {
  journeyId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetJourneysArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<StatusTypes>>>;
  sortBy?: InputMaybe<SortByTypes>;
  portfolioType?: InputMaybe<Array<InputMaybe<Enum_PortfolioType>>>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCalculateRiskJourneyArgs = {
  journeyId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetStyleRecommendationArgs = {
  journeyId: Scalars['ID']['input'];
};

export type QueryGetReturnsFileArgs = {
  returnsId: Scalars['ID']['input'];
};

export type QueryGetHistoryArgs = {
  value: Scalars['Float']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUsersByGroupNameArgs = {
  groupName: CognitoGroup;
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUserLogoStatusArgs = {
  id: Scalars['ID']['input'];
};

export type ResetPasswordResult = {
  __typename?: 'ResetPasswordResult';
  temporaryPassword: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Result = {
  __typename?: 'Result';
  success: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type Returns = {
  __typename?: 'Returns';
  returnsId: Scalars['String']['output'];
  fileContent?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  rows: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  createdAt: Scalars['String']['output'];
};

export type Risk = {
  __typename?: 'Risk';
  riskId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Enum_RiskName>;
  riskLevel?: Maybe<Scalars['Float']['output']>;
  annualReturn?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
  bestOneYear?: Maybe<Scalars['Float']['output']>;
  worstOneYear?: Maybe<Scalars['Float']['output']>;
};

export enum SortByTypes {
  InvestorName = 'investorName',
  PortfolioName = 'portfolioName',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  Name = 'name',
  Status = 'status',
  PortfolioType = 'portfolioType',
  PortfolioValue = 'portfolioValue',
}

export type Statistics = {
  __typename?: 'Statistics';
  createdAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  low?: Maybe<Variables>;
  lower?: Maybe<Variables>;
  medium?: Maybe<Variables>;
  higher?: Maybe<Variables>;
  high?: Maybe<Variables>;
};

export enum StatusTypes {
  InProgress = 'inProgress',
  Complete = 'complete',
  QuestionnaireGenerated = 'questionnaireGenerated',
  QuestionnaireInProgress = 'questionnaireInProgress',
  QuestionnaireSubmitted = 'questionnaireSubmitted',
}

export type StylePreference = {
  __typename?: 'StylePreference';
  recommendation?: Maybe<Enum_PortfolioStyle>;
  number?: Maybe<Scalars['Int']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateJourney?: Maybe<Journey>;
  onUpdateJourney?: Maybe<Journey>;
};

export type TimeStampInput = {
  topLine: Scalars['String']['input'];
  bottomLine: Scalars['String']['input'];
};

export type TimeStampType = {
  __typename?: 'timeStampType';
  topLine?: Maybe<Scalars['String']['output']>;
  bottomLine?: Maybe<Scalars['String']['output']>;
};

export type Type_CalculatedRiskProfileQuestion = {
  __typename?: 'Type_CalculatedRiskProfileQuestion';
  question1?: Maybe<Scalars['Boolean']['output']>;
  question2?: Maybe<Scalars['Boolean']['output']>;
  question3?: Maybe<Scalars['Boolean']['output']>;
  question4?: Maybe<Scalars['Boolean']['output']>;
  question5?: Maybe<Scalars['Boolean']['output']>;
  question6?: Maybe<Scalars['Boolean']['output']>;
  question7?: Maybe<Scalars['Boolean']['output']>;
  question8?: Maybe<Scalars['Boolean']['output']>;
  timeHorizon?: Maybe<Scalars['Boolean']['output']>;
};

export type Type_ChosenSatellites = {
  __typename?: 'Type_ChosenSatellites';
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
  risk: Scalars['Int']['output'];
  sateliteId: Scalars['String']['output'];
};

export type Type_CompleteRiskProfile = {
  __typename?: 'Type_CompleteRiskProfile';
  confidenceComposure?: Maybe<Enum_ConfidenceComposure>;
  toleranceCapitalLoss?: Maybe<Scalars['Int']['output']>;
  feelAboutRisk?: Maybe<Type_TwoQuestionAnswerFromOneToFive>;
  riskTolerance?: Maybe<Scalars['Int']['output']>;
  pastExperience?: Maybe<Enum_PastExperience>;
  riskCapacity?: Maybe<Type_RiskCapacity>;
  output?: Maybe<Scalars['Int']['output']>;
};

export type Type_Income = {
  __typename?: 'Type_Income';
  notes?: Maybe<Scalars['String']['output']>;
  incomeAnnum?: Maybe<Scalars['Float']['output']>;
  yield?: Maybe<Scalars['Float']['output']>;
  selected?: Maybe<Enum_Income_WithdrawalType>;
};

export type Type_IncomeCostSustainability = {
  __typename?: 'Type_IncomeCostSustainability';
  notes?: Maybe<Scalars['String']['output']>;
  income?: Maybe<Scalars['Boolean']['output']>;
  cost?: Maybe<Enum_KnwoledgeRange>;
  susainability?: Maybe<Enum_KnwoledgeRange>;
};

export type Type_KnowledgeExperience = {
  __typename?: 'Type_KnowledgeExperience';
  notes?: Maybe<Scalars['String']['output']>;
  typeService?: Maybe<Array<Maybe<Enum_KnowledgeExperience_TypeService>>>;
  typeAsset?: Maybe<Array<Maybe<Enum_KnowledgeExperience_TypeAsset>>>;
  duration?: Maybe<Enum_KnowledgeExperience_Duration>;
  noInvestment?: Maybe<Scalars['Boolean']['output']>;
};

export type Type_PortfolioCost = {
  __typename?: 'Type_PortfolioCost';
  notes?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Enum_PortfolioCost>;
};

export type Type_PortfolioPdf = {
  __typename?: 'Type_PortfolioPdf';
  report?: Maybe<Scalars['String']['output']>;
  factsheet?: Maybe<Scalars['String']['output']>;
};

export type Type_PortfolioSelection = {
  __typename?: 'Type_PortfolioSelection';
  notes?: Maybe<Scalars['String']['output']>;
  riskProfileSelection?: Maybe<Enum_PortfolioSelection>;
  portfolioStyle?: Maybe<Type_PortfolioStyle>;
};

export type Type_PortfolioStyle = {
  __typename?: 'Type_PortfolioStyle';
  notes?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Enum_PortfolioStyle>;
};

export type Type_PortfolioType = {
  __typename?: 'Type_PortfolioType';
  notes?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Array<Maybe<PortfolioTypeEntityType>>>;
};

export type Type_ReasonsInvesting = {
  __typename?: 'Type_ReasonsInvesting';
  notes?: Maybe<Scalars['String']['output']>;
  selectedName?: Maybe<Enum_ReasonsInvesting_SelectedName>;
  saveAndInvest?: Maybe<Enum_ReasonsInvesting_SaveAndInvest>;
  retirementPlanning?: Maybe<Enum_ReasonsInvesting_RetirementPlanning>;
  targetInvesting?: Maybe<Enum_ReasonsInvesting_TargetInvesting>;
  supplementaryIncome?: Maybe<Enum_ReasonsInvesting_SupplementaryIncome>;
};

export type Type_ReportPdfOptions = {
  __typename?: 'Type_ReportPdfOptions';
  excludedPages?: Maybe<Array<Maybe<Enum_ReportPdfPages>>>;
};

export type Type_ResponsibleInvesting = {
  __typename?: 'Type_ResponsibleInvesting';
  notes?: Maybe<Scalars['String']['output']>;
  question1?: Maybe<Enum_ResponsibleInvesting_Question1>;
  question2?: Maybe<Enum_ResponsibleInvesting_Question2>;
};

export type Type_RiskCapacity = {
  __typename?: 'Type_RiskCapacity';
  question1?: Maybe<Enum_RiskCapacity_Question1>;
  question2?: Maybe<Enum_RiskCapacity_Question2>;
};

export type Type_RiskProfile = {
  __typename?: 'Type_RiskProfile';
  notes?: Maybe<Scalars['String']['output']>;
  selectedName?: Maybe<Enum_RiskProfile_SelectedName>;
  completeRiskProfile?: Maybe<Type_CompleteRiskProfile>;
};

export type Type_SatellitePortfolios = {
  __typename?: 'Type_SatellitePortfolios';
  notes?: Maybe<Scalars['String']['output']>;
  chosenSatellites?: Maybe<Array<Maybe<Type_ChosenSatellites>>>;
};

export type Type_Source = {
  __typename?: 'Type_Source';
  id: Scalars['String']['output'];
  name: Enum_PortfolioType;
  value: Scalars['Float']['output'];
  note?: Maybe<Scalars['String']['output']>;
  portfolios?: Maybe<Array<Type_SourcePortfolio>>;
};

export type Type_SourcePortfolio = {
  __typename?: 'Type_SourcePortfolio';
  key: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
  risk: Enum_PortfolioSelection;
  cost?: Maybe<Scalars['Float']['output']>;
};

export type Type_TaxWrapper = {
  __typename?: 'Type_TaxWrapper';
  notes?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Type_Source>>;
};

export type Type_TimeHorizon = {
  __typename?: 'Type_TimeHorizon';
  notes?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Float']['output']>;
};

export type Type_TwoQuestionAnswerFromOneToFive = {
  __typename?: 'Type_TwoQuestionAnswerFromOneToFive';
  question1?: Maybe<Scalars['Int']['output']>;
  question2?: Maybe<Scalars['Int']['output']>;
};

export type UpdateCompanyUserInput = {
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  brandColor?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFactSheetInput = {
  factSheetId: Scalars['ID']['input'];
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  ownedByDisplayName?: InputMaybe<Scalars['String']['input']>;
  objective: Scalars['String']['input'];
  marketPortfolioUpdate: Scalars['String']['input'];
  portfolioPositioningChanges?: InputMaybe<Scalars['String']['input']>;
  cumulativePerformance?: InputMaybe<CumulativePerformanceInput>;
  portfolioCharacteristics: PortfolioCharacteristicsInput;
  timeStamp: TimeStampInput;
  fixedIncome: Array<InputMaybe<NameValueInput>>;
  realReturn: Array<InputMaybe<NameValueInput>>;
  absoluteReturn: Array<InputMaybe<NameValueInput>>;
  equities: Array<InputMaybe<NameValueInput>>;
  realAssets: Array<InputMaybe<NameValueInput>>;
  riskWarnings?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIfaUserInput = {
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};

export type UpdateJourneyInput = {
  journeyId: Scalars['ID']['input'];
  version?: InputMaybe<Enum_JourneyVersion>;
  step: JourneyStep;
  completedSteps?: InputMaybe<Array<InputMaybe<JourneyStep>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  portfolioName?: InputMaybe<Scalars['String']['input']>;
  portfolioValue?: InputMaybe<Scalars['Float']['input']>;
  investorEmail?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  refreshReport?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioType?: InputMaybe<Input_PortfolioType>;
  reasonsInvesting?: InputMaybe<Input_ReasonsInvesting>;
  income?: InputMaybe<Input_Income>;
  timeHorizon?: InputMaybe<Input_TimeHorizon>;
  knowledgeExperience?: InputMaybe<Input_KnowledgeExperience>;
  responsibleInvesting?: InputMaybe<Input_ResponsibleInvesting>;
  riskProfile?: InputMaybe<Input_RiskProfile>;
  incomeCostSustainability?: InputMaybe<Input_IncomeCostSustainability>;
  approvedRisk?: InputMaybe<Scalars['Boolean']['input']>;
  satelliteSelection?: InputMaybe<Scalars['ID']['input']>;
  portfolioSelection?: InputMaybe<Input_PortfolioSelection>;
  satellitePortfolios?: InputMaybe<Input_SatellitePortfolios>;
  taxWrapper?: InputMaybe<Input_TaxWrapper>;
  portfolioCost?: InputMaybe<Input_PortfolioCost>;
  complete: Scalars['Boolean']['input'];
  isShort?: InputMaybe<Scalars['Boolean']['input']>;
  isRiskProfile?: InputMaybe<Scalars['Boolean']['input']>;
  pdf?: InputMaybe<Input_PortfolioPdf>;
  reportOptions?: InputMaybe<Input_ReportPdfOptions>;
};

export type UpdateLabelInput = {
  labelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  boom: Scalars['Boolean']['input'];
};

export type UpdatePdfChartInput = {
  factsheetId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  portfolioChange?: InputMaybe<Scalars['Float']['input']>;
  benchmarkChange?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePortfolioInput = {
  portfolioId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  low: Scalars['Float']['input'];
  lower: Scalars['Float']['input'];
  medium: Scalars['Float']['input'];
  higher: Scalars['Float']['input'];
  high: Scalars['Float']['input'];
};

export type UpdateRiskInput = {
  riskId: Scalars['String']['input'];
  riskLevel: Scalars['Float']['input'];
  annualReturn: Scalars['Float']['input'];
  volatility: Scalars['Float']['input'];
  bestOneYear: Scalars['Float']['input'];
  worstOneYear: Scalars['Float']['input'];
};

export type UpdateStatisticsInput = {
  low: VariablesInput;
  lower: VariablesInput;
  medium: VariablesInput;
  higher: VariablesInput;
  high: VariablesInput;
};

export type UploadReturnsInput = {
  fileContent: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  rows: Array<Array<Scalars['String']['input']>>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  brandColor?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
};

export type Values = {
  __typename?: 'Values';
  low?: Maybe<Scalars['Float']['output']>;
  lower?: Maybe<Scalars['Float']['output']>;
  medium?: Maybe<Scalars['Float']['output']>;
  higher?: Maybe<Scalars['Float']['output']>;
  high?: Maybe<Scalars['Float']['output']>;
};

export type Variables = {
  __typename?: 'Variables';
  annualReturn?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
  bestOneYear?: Maybe<Scalars['Float']['output']>;
  worstOneYear?: Maybe<Scalars['Float']['output']>;
};

export type VariablesInput = {
  annualReturn: Scalars['Float']['input'];
  volatility: Scalars['Float']['input'];
  bestOneYear: Scalars['Float']['input'];
  worstOneYear: Scalars['Float']['input'];
};
