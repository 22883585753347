import { createIcon } from '../createIcon';

export const InAppNotificationIcon = createIcon({
  svg: () => (
    <g>
      <path
        d="M11.9966 21.3846C11.5514 21.3846 11.1715 21.2265 10.8567 20.9101C10.542 20.5938 10.3846 20.2135 10.3846 19.7692H13.6154C13.6154 20.2167 13.4569 20.5978 13.1399 20.9125C12.8229 21.2273 12.4418 21.3846 11.9966 21.3846ZM5 18.7692V17.7692H6.61537V9.84615C6.61537 8.53975 7.02884 7.39072 7.85577 6.39905C8.68269 5.40737 9.73077 4.78973 11 4.54615V4C11 3.72222 11.097 3.4861 11.291 3.29165C11.485 3.09722 11.7206 3 11.9978 3C12.2749 3 12.5112 3.09722 12.7067 3.29165C12.9022 3.4861 13 3.72222 13 4V4.53683C13.2872 4.60074 13.5635 4.67781 13.8291 4.76803C14.0946 4.85824 14.3446 4.97274 14.5788 5.11153C14.4663 5.24144 14.369 5.37745 14.2868 5.51955C14.2046 5.66165 14.1224 5.8077 14.0404 5.9577C13.7365 5.79617 13.4137 5.67309 13.0719 5.58848C12.7302 5.50386 12.3728 5.46155 12 5.46155C10.7846 5.46155 9.75 5.88847 8.89615 6.7423C8.0423 7.59615 7.61537 8.63077 7.61537 9.84615V17.7692H16.3846V14.3096C16.5397 14.4058 16.6985 14.4973 16.8609 14.5842C17.0233 14.6712 17.1979 14.7424 17.3846 14.7981V17.7692H19V18.7692H5Z"
        fill="currentColor"
      />
      <circle cx="19.5" cy="8.5" r="3.5" fill="currentColor" />
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 24 24',
});
