import { createIcon } from '../createIcon';

export const MultiStrategyIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.875 22.0714H23.9821V28.5H29.875V22.0714ZM23.9821 21C23.4397 21 23 21.4797 23 22.0714V28.5C23 29.0917 23.4397 29.5714 23.9821 29.5714H29.875C30.4174 29.5714 30.8571 29.0917 30.8571 28.5V22.0714C30.8571 21.4797 30.4174 21 29.875 21H23.9821Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.875 43.5H23.9821V49.9286H29.875V43.5ZM23.9821 42.4286C23.4397 42.4286 23 42.9083 23 43.5V49.9286C23 50.5203 23.4397 51 23.9821 51H29.875C30.4174 51 30.8571 50.5203 30.8571 49.9286V43.5C30.8571 42.9083 30.4174 42.4286 29.875 42.4286H23.9821Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.875 32.7857H23.9821V39.2143H29.875V32.7857ZM23.9821 31.7143C23.4397 31.7143 23 32.194 23 32.7857V39.2143C23 39.806 23.4397 40.2857 23.9821 40.2857H29.875C30.4174 40.2857 30.8571 39.806 30.8571 39.2143V32.7857C30.8571 32.194 30.4174 31.7143 29.875 31.7143H23.9821Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4464 32.7857H47.5536V39.2143H53.4464V32.7857ZM47.5536 31.7143C47.0111 31.7143 46.5714 32.194 46.5714 32.7857V39.2143C46.5714 39.806 47.0111 40.2857 47.5536 40.2857H53.4464C53.9889 40.2857 54.4286 39.806 54.4286 39.2143V32.7857C54.4286 32.194 53.9889 31.7143 53.4464 31.7143H47.5536Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7321 25.8214H32.8214V24.75H37.7321C38.5458 24.75 39.2054 25.4695 39.2054 26.3571V45.6429C39.2054 46.5305 38.5458 47.25 37.7321 47.25H32.8214V46.1786H37.7321C38.0034 46.1786 38.2232 45.9387 38.2232 45.6429V26.3571C38.2232 26.0613 38.0034 25.8214 37.7321 25.8214Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6071 36.5357H32.8214V35.4643H44.6071V36.5357Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 72 72',
});
